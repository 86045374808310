import React from "react";
import fireSparkles from "../../../../assets/animations/particles.json";
import { S3_BUCKET } from "../../../../utils/Constants";
import Button from "../../Buttons/Button/Button";
import LottieAnimation from "../../LottieAnimation/LottieAnimation";
import styles from "./GameCard.module.scss";

const GameCard = ({
	playCard,
	dataAos,
	gameImage,
	gameImageClass,
	gameTitle,
	comingSoon,
	lockedCard,
	className,
	onPlayClick,
	WatchVideoClick,
	setShow,
	glowing,
	item,
	hideButtons,
	titleClassName,
	active,
	silverCard,
	onClick,
}) => {

	const handlePlayNow = () => {
		setShow(true)
	};

	return (
		<div
			{...dataAos}
			className={`${styles.game_card} ${onClick ? "clickable" : ""} ${glowing ? styles.glowing : ""} ${playCard ? styles.play_card : ""} ${lockedCard ? styles.locked_card : ""} ${className || ""}`} data-active={active}
			onClick={onClick}
		>
			<div className={styles.game_inner}>
				<span className={`${styles.left_arrow} ${"left_arrow"}`}>
					<img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="" />
				</span>
				<span className={`${styles.right_arrow} ${"right_arrow"}`}>
					<img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="" />
				</span>
				<span className={styles.archery_icon}>
					{
						!silverCard ?
							<img
								src={`${S3_BUCKET.IMAGES}/game-archery-2.png`}
								alt="archery-icon"
							/>
							:
							<>
								<img
									src={`${S3_BUCKET.SILVER_BOW}`}
									alt="archery-icon"
									className={"silver-bow"}
								/>
								<img
									src={`${S3_BUCKET.IMAGES}/game-archery-2.png`}
									alt="archery-icon"
									className={'d-none golden-bow'}
								/>
							</>

					}
				</span>
				<div className={`${styles.game_image} ${comingSoon ? styles.coming_soon : ""} ${gameImageClass || ""}`}>
					<img src={gameImage} alt="game" />
				</div>
				<div className={`${styles.game_title} ${titleClassName || ""}`}>
					<h2>{gameTitle}</h2>
					{comingSoon && (
						<div className={styles.coming_soon_img}>
							<p>Coming Soon</p>
							<img
								src={`${S3_BUCKET.IMAGES}/coming-soon-game.png`}
								alt="coming-soon"
							/>
						</div>
					)}
					{lockedCard && (
						<div className={`${styles.coming_soon_img} ${styles.locked_img}`}>
							<img src={`${S3_BUCKET.IMAGES}/lockedgame.png`} alt="lock" />
						</div>
					)}
				</div>
				<LottieAnimation
					animation={fireSparkles}
					className={styles.fire_sparkles}
				/>
				{
					!hideButtons && <>
						{comingSoon ? null : playCard ? (
							<Button
								// onClick={() => navigate('/login')}
								text={item?.name.toLowerCase() === "ram mandir ka nirman" ? "Enter" : "Enter"}
								variant="secondary-btn"
								className={styles.action_btn}
								onClick={onPlayClick}
							/>
						) : lockedCard ? (
							<Button
								onClick={WatchVideoClick}
								text="Watch Video"
								variant="secondary-btn"
								className={styles.action_btn}
							/>
						) : (
							<Button
								text="Play Now"
								className={styles.action_btn}
								onClick={handlePlayNow}
							/>
						)}
					</>
				}
			</div>
		</div>
	);
};

export default GameCard;
