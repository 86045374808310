
import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import Cookies from "js-cookie";
import styles from "./GameplayCinematicReact.module.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../Buttons/Button/Button";
import Loader from "../../Loader/Loader";

const GamePlayCinematicReact = ({ videoUrl, subtitles ,gamePlay ,isLoading}) => {
  const storedLanguage = Cookies.get("googtrans") === "undefined"  ?  "/en/en" : Cookies.get("googtrans");
  const [trackLang, setTrackLang] = useState(storedLanguage); // Set a default language
  const [showButton, setShowButton] = useState(true);
  const [isLoadings, setIsLoadings] = useState(true);
  const player = useRef();

  const onVideoEnd = () => {
    setShowButton(true);
    // player.current.play()
  };

  const handleReady = () => {
    setIsLoadings(false);
  };

  const handleBuffer = () => {
    setIsLoadings(true);
  };
 
  return (
    <div className={styles.gameplay_main}>
       {isLoadings && (
        <Loader/>
      )}
      <ReactPlayer
        ref={player}
        height={"100%"}
        width={"100%"}
        config={{
          file: {
            attributes: {
              crossOrigin: "*",
            },
            tracks: subtitles.map((subtitle) => ({
              ...subtitle,
              // mode: trackLang === subtitle?.srcLang,
              default: trackLang === subtitle?.srcLang,
            })),
          },
        }}
        url={videoUrl}
        playing
        playsinline
        loop
        onReady={handleReady}
        onBuffer={handleBuffer}
        onEnded={onVideoEnd}
      />
      {/* <button
        onClick={() => { navigate('/participation') }}
        className={styles.skip_btn}>
        Skip
      </button> */}

    
       {showButton && (
          <Button noAos={true} loading={isLoading} onClick={gamePlay} className={styles.continue_btn}>Continue To Activity</Button>
        )}
    </div>
  );
};

export default GamePlayCinematicReact;

