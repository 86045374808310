import React from "react";
import Button from "../../Buttons/Button/Button";
import CommonModal from "../CommonModal/CommonModal";
import styles from "./AfterShare.module.scss";
import { CrossIcon } from "../../../../assets/svg/svgicons";

const AfterShare = ({ show, handleClose }) => {


	return (
		<>
			<CommonModal
				show={show}
				handleClose={handleClose}
				keyboard={false}
				backdropClassName={"audio_modal_backdrop"}
				className={`${styles.audio_modal}`}
				title="Please share one more time to access the VVIP queue"
			>
                 <button onClick={handleClose} className={styles.close_btn}>
                
                    <CrossIcon />
                </button>
			</CommonModal>
		</>
	);
};

export default AfterShare;
