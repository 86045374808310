import React from 'react';
import { useNavigate } from 'react-router-dom';
import { S3_BUCKET, getBrowserType } from '../../../../utils/Constants';
import styles from "../VideoSkip.module.scss";

const BeforeVideo = () => {
  const navigate = useNavigate()
  const isBrowserType = getBrowserType()
  const onVideoEnd = () => {
    navigate("/select-asset-activity", {
      state: {
        before: true,
      },
    });
  }
  
  return (
    <>
      <section className={styles.video}>
        <video playsInline autoPlay onEnded={onVideoEnd}>
          {isBrowserType === "Safari" ? 
          <source src={S3_BUCKET.BEFORE_END_BUY_SAFARI} type="video/mp4" />
          :
          <source src={S3_BUCKET.BEFORE_END_BUY} type="video/mp4" />
           }
        </video>
      </section>
    </>
  )
}

export default BeforeVideo