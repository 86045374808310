import React from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { S3_BUCKET, getBrowserType } from "../../../utils/Constants";
import styles from "./ContentLayout.module.scss";
const ContentLayout = ({ children, className, leftContentClass, image, isMuted, rightContentClass, videoRef }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const browserType = getBrowserType()

  return (
    <section className={`${`${styles.content_layout} ${browserType === "Safari" ? styles.safari : ""}`} ${className || ""}`}>
      <Container>
        <div className={styles.content_inner}>
          <div className={`${styles.content_left} ${leftContentClass || ""}`}>
            <div className={styles.content_left_inner}>{children}</div>
          </div>
          <div className={`${`${styles.content_right} `} ${rightContentClass || ""}`}>
            {location.pathname === "/login" && (
              <video muted={isMuted} playsInline ref={videoRef}>
                {browserType === "Safari" ?
                  <source src={S3_BUCKET.LOGIN_SAFARI} type="video/mp4" />
                  :
                  <source src={S3_BUCKET.SHARE_PAGE_VIDEO} type="video/mp4" />
                }
              </video>
            )}

            {location.pathname === "/email-verify" && (
              <video playsInline ref={videoRef} onEnded={() => navigate('/participation')}>
                {browserType === "Safari" ?
                  <source src={S3_BUCKET.EMAIL_VERIFY_SAFARI} type="video/mp4" />
                  :
                  <source src={S3_BUCKET.USER_NOT_EXIST_VIDEO} type="video/mp4" />
                }
              </video>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};
export default ContentLayout;