import Header from "../components/common/Header/Header";
import AboutUs from "../components/pages/AboutUs/AboutUs";
import AccessCollectibles from "../components/pages/AccessCollectibles/AccessCollectibles";
import AfterGameEnd from "../components/pages/AfterGameEnd/AfterGameEnd";
import AfterVideo from "../components/pages/VideoSkip/AfterVideo/AfterVideo";
import BeforeVideo from "../components/pages/VideoSkip/BeforeVideo/BeforeVideo";
import Checkout from "../components/pages/Checkout/Checkout";
import CheckoutGame from "../components/pages/CheckoutGame/CheckoutGame";
import ContactUs from "../components/pages/ContactUs/ContactUs";
import ContinueGame from "../components/pages/ContinueGame/ContinueGame";
import Dhyana from "../components/pages/Dhyana/Dhyana";
import Games from "../components/pages/Games/Games";
import Homepage from "../components/pages/Homepage/Homepage";
import Login from "../components/pages/Login/Login";
import Mycollectible from "../components/pages/Mycollectible/Mycollectible";
import Outside from "../components/pages/OutsidePayment/Outside";
import Participation from "../components/pages/Participation/Participation";
import Payment from "../components/pages/Payment/Payment";
import PrivacyPolicy from "../components/pages/PrivacyPolicy/PrivacyPolicy";
import QuesPage from "../components/pages/QuesPage/QuesPage";
import QuestionRight from "../components/pages/QuestionRight/QuestionRight";
import SelectAsset from "../components/pages/SelectAsset/SelectAsset";
import SelectAssetGame from "../components/pages/SelectAssetGame/SelectAssetGame";
import SharePage from "../components/pages/SharePage/SharePage";
import TermServices from "../components/pages/TermServices/TermServices";
import VerifyEmailPage from "../components/pages/VerifyEmailPage/VerifyEmailPage";
import VideoSkip from "../components/pages/VideoSkip/VideoSkip";
import ViewAsset from "../components/pages/ViewAsset/ViewAsset";
import ViewAssetGame from "../components/pages/ViewAssetGame/ViewAssetGame";
import WelCome from "../components/pages/WelcomePage/WelCome";
import Yoga from "../components/pages/Yoga/Yoga";
import { S3_BUCKET } from "../utils/Constants";
import ProtectedRoute from "./ProtectedRoute";
import SkipShare from "../components/pages/SkipShare/SkipShare";
import SkipShareVideo from "../components/pages/SkipShare/SkipShareVideo";
import Footer from "../components/common/Footer/Footer";
import BrickAsset from "../components/pages/BrickAsset/BrickAsset";


const routes = [
  // {
  //   path: `login`,
  //   element: (
  //     <Login />
  //   ),
  // },
  {
    path: "question",
    element: (
      <QuesPage />
    ),
  },
  {
    path: "/",
    element: <WelCome />,
  },
  {
    // path: "activity",
    path: "/welcome",
    element: <Games />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "continue",
    element: <ContinueGame />,
  },
  {
    path: "participation",
    element: <Participation />,
  },
  {
    path: "home",
    element: <Homepage />,
  },
  {
    path: "my-collectibles",
    element: (
      <ProtectedRoute>
        <Header logo={S3_BUCKET.DIGITAL_LOGO} />
        <Mycollectible />
      </ProtectedRoute>
    ),
  },
  {
    path: "view-asset",
    element: (
      // <ProtectedRoute>
      <>
        {/* <Header /> */}
        <ViewAsset />
      </>
      // </ProtectedRoute>
    ),
  },
  {
    path: "email-verify",
    element:
      (
        <VerifyEmailPage />
      )
  },
  {
    path: "select-asset",
    element: (
      // <ProtectedRoute>
      <>
        <Header logo={S3_BUCKET.DIGITAL_LOGO} />
        <SelectAsset />
        <Footer />
      </>
      // </ProtectedRoute>
    ),
  },
  {
    path: "brick",
    element: (
      // <ProtectedRoute>
      <>
        <BrickAsset />
      </>
      // </ProtectedRoute>
    ),
  },
  {
    path: "view-asset-activity",
    element: <ViewAssetGame />
  },
  {
    path: "checkout-activity",
    element: <CheckoutGame />
  },
  {
    path: "/",
    element: <VideoSkip />,
    children: [
      {
        path: "select-asset-activity",
        element: (
          <>
        <Header logo={S3_BUCKET.DIGITAL_LOGO} />
            <SelectAssetGame />
            <Footer />
          </>
        ),
      },
      {
        path: "before-video",
        element: <BeforeVideo />
      },
      {
        path: "after-video",
        element: <AfterVideo />
      },
    ]
  },

  {
    path: "/",
    element: <SkipShare />,
    children: [
      {
        path: "share",
        element: <SharePage />
      },
      {
        path: "skip-video",
        element: <SkipShareVideo />
      },
    ]
  },

  // {
  //   index: true,
  //   element: (

  //     <HomeVideo />

  //   ),
  // },
  {
    path: "about-us",
    element: <AboutUs />,
  },

  {
    path: "navigator",
    element: <Homepage />,
  },
  {
    path: "collectibles",
    element: <AccessCollectibles />,
  },
  {
    path: "continues",
    element: <Outside />,
  },
  {
    path: "payment",
    element: (
      <Payment />
    ),
  },
  {
    path: "share",
    element: (
      <SharePage />
      // <ProtectedRoute>        
      // </ProtectedRoute> 
    ),
  },
  {
    path: "checkout",
    element: (
      // <ProtectedRoute>
      <>
        <Header />
        <Checkout />
      </>
      // </ProtectedRoute>
    ),
  },
  {
    path: "yoga",
    element: <Yoga />,
  },
  {
    path: "dhyana",
    element: <Dhyana />,
  },
  {
    path: "contact",
    element: <ContactUs />
  },
  {
    path: "question-right",
    element: <QuestionRight />,
  },
  {
    path: "points",
    element: <AfterGameEnd />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "terms-services",
    element: <TermServices />,
  },
  {
    path: "verify-email",
    element: <VerifyEmailPage />,
  },
];


export default routes;
