import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { apiCallPost } from "../../../axiosApi/Axios";
import { ENVIRONMENT, GAME_TOKEN, NETWORK_SPEED, S3_BUCKET } from "../../../utils/Constants";
import GameCard from "../../common/Cards/GameCard/GameCard";
import HeadingText from "../../common/HeadingText/HeadingText";
import GamePlayVideo from "../../common/Modals/GamePlayVideo/GamePlayVideo";
import GameplayCinematic from "../../common/Modals/GameplayCinematic/GameplayCinematic";
import styles from "./Participation.module.scss";
// import EmailVerify from "../../common/Modals/EmailVerify/EmailVerify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GamePlayBefore from "../../common/Modals/GamePlayBefore/GamePlayBefore";
import WelcomeToSiteModal from "../../common/Modals/WelcomeToSiteModal/WelcomeToSiteModal";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../redux/loader";
import SelectMode from "../../common/Modals/SelectMode/SelectMode";
import GamePlayVideoReact from "../../common/Modals/GamePlayVideoReact/GamePlayVideoReact";
import GamePlayCinematicReact from "../../common/Modals/GamePlayCinematicReact/GamePlayCinematicReact";

const Participation = () => {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	const hasMounted = useRef(false);
	const [show, setShow] = useState(false);
	const [collectibleType, setCollectibleType] = useState(false);
	const [showgamplay, setShowGamplay] = useState(false);
	const [itemToVerify, setItemToVerify] = useState('');
	const [video, setVideo] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [showVideo, setShowVideo] = useState(false);
	const [welModal, setWelModal] = useState(true)
	const navigate = useNavigate()
	const speed = useSelector((state) => state.network.speed);
	const dispatch = useDispatch()
	const [showMode, setShowMode] = useState(false);

	// const audioRef= useRef()


	const onPlayClick = async (item) => {
		setShowGamplay(true);
		setItemToVerify(item)
	};
	const onOnotherPlayClick = (item) => {
		navigate("/email-verify", {
			state: {
				itemToVerify: item,
			},
		});
		setItemToVerify(item)
	};

	const WatchVideoClick = (video) => {
		setVideo(video);
		setShow(true);
	};
	// useEffect(() => {
	//   audioRef?.current && audioRef.current.play();
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [audioRef?.current]);


	useEffect(() => {
		if (!hasMounted.current) {
			// This block will only run on the initial render
			hasMounted.current = true;
			dispatch(setLoader(true));

			const getContactListType = async () => {
				try {
					let res = await apiCallPost(
						`/api/v1/satyug/collectableType/${'20'}/${'1'}`,
						{},
						true,
						false
					);

					if (!res?.error) {
						setCollectibleType(res?.data);
						dispatch(setLoader(false));
					} else {
						// Handle the error case
						console.error('Error fetching collectable type:', res?.error);
					}
				} catch (error) {
					console.error('An unexpected error occurred:', error);
				} finally {
					dispatch(setLoader(false));
				}
			};

			getContactListType();
		}
	}, []);



	const settings = {
		dots: false,
		arrows: true,
		infinite: false,
		slidesToShow: 5,
		responsive: [
			{
				breakpoint: 479,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 399,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 349,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};
	let imageUrl = useMemo(() => {
		switch (speed) {
			case NETWORK_SPEED.LOW:
				return S3_BUCKET.BEFORE_GAME_360;
			case NETWORK_SPEED.MEDIUM:
				return S3_BUCKET.BEFORE_GAME_480;
			case NETWORK_SPEED.HIGH:
				return S3_BUCKET.BEFORE_GAME_720;
			default:
				return S3_BUCKET.BEFORE_GAME_360;
		}
	}, [speed]);


   
	const subtitles = [
		{
		  kind: "subtitles",
		  src:"https://dvf7opio6knc7.cloudfront.net/subtitle/mandir/en.vtt",
		  srcLang: "/en/en",
		  default: false,
		},
		{
		  kind: "subtitles",
		  src:"https://dvf7opio6knc7.cloudfront.net/subtitle/mandir/hi.vtt",
		  srcLang: "/en/hi",
		  default: false,
		},
		{
		  kind: "subtitles",
		  src:"https://dvf7opio6knc7.cloudfront.net/subtitle/mandir/bn.vtt",
		  srcLang: "/en/bn",
		  default: false,
		},
		{
		  kind: "subtitles",
		  src:"https://dvf7opio6knc7.cloudfront.net/subtitle/mandir/gu.vtt",
		  srcLang: "/en/gu",
		  default: false,
		},
		{
		  kind: "subtitles",
		  src:"https://dvf7opio6knc7.cloudfront.net/subtitle/mandir/kn.vtt",
		  srcLang: "/en/kn",
		  default: false,
		},
		{
		  kind: "subtitles",
		  src:"https://dvf7opio6knc7.cloudfront.net/subtitle/mandir/mr.vtt",
		  srcLang: "/en/mr",
		  default: false,
		},
		{
		  kind: "subtitles",
		  src:"https://dvf7opio6knc7.cloudfront.net/subtitle/mandir/ta.vtt",
		  srcLang: "/en/ta",
		  default: false,
		},
		{
		  kind: "subtitles",
		  src:"https://dvf7opio6knc7.cloudfront.net/subtitle/mandir/te.vtt",
		  srcLang: "/en/te",
		  default: false,
		},
	  ];



	return (
		<>
			{/* <audio ref={audioRef} loop>
        <source src={S3_BUCKET_AUDIO.PARTICIPATION} type="audio/mpeg" />
      </audio> */}
			<section className={styles.participation}>
				<Container className="position-relative">
					<HeadingText
						dataAos={{
							"data-aos": "zoom-in",
						}}
						heading="What part would you like to contribute to"
						className={styles.heading}
				/>
					<Slider
						asNavFor={nav2}
						draggable={false}
						infinite={false}
						ref={slider => setNav1(slider)} arrows={false} slidesToShow={1} className={styles.main_img}>
						{collectibleType?.count > 0 ? (
							collectibleType?.rows?.map((item) => {
								return (
									item.isComingSoon !== 1 ?
										<GameCard
											key={item.id}
											className={styles.main_img_card}
											gameImageClass={styles.main_img_card_img}
											dataAos={{
												"data-aos": "zoom-in",
												"data-aos-delay": "100",
											}}
											gameImage={item?.image}
											gameTitle={item?.name}
											item={item}
											onClick={() =>
												item?.name.toLowerCase() === "ram mandir ka nirman"
													? onPlayClick(item)
													: onOnotherPlayClick(item)
											}
											playCard={true}
											onPlayClick={() =>
												item?.name.toLowerCase() === "ram mandir ka nirman"
													? onPlayClick(item)
													: onOnotherPlayClick(item)
											}
										/>
										:
										<GameCard
											key={item.id}
											dataAos={{
												"data-aos": "fade-up",
												"data-aos-delay": "300",
											}}
											// className={styles.main_img_card}
											// gameImageClass={styles.game_card_img}
											gameImageClass={styles.main_img_card_img}
											className={styles.main_img_card}
											comingSoon={true}
											// gameImage={`${S3_BUCKET.IMAGES}/game-card4.png`}
											gameImage={item?.image}

										/>
								)
							})
						) : (
							null
						)}
						{/* <GameCard
              dataAos={{
                "data-aos": "fade-up",
                "data-aos-delay": "300",
              }}
              className={styles.main_img_card}
              gameImageClass={styles.game_card_img}
              comingSoon={true}
              gameImage={`${S3_BUCKET.IMAGES}/game-card4.png`}
            /> */}
					</Slider>
					<Slider
						draggable={false}
						focusOnSelect={slide => console.log('slide', slide)}
						ref={slider => setNav2(slider)}
						asNavFor={nav1}
						slide={false}
						className={styles.slick_slider} {...settings}>
						{collectibleType?.count > 0 ? (
							collectibleType?.rows?.map((item) => (
								<GameCard
									key={item.id}
									className={styles.participate_game}
									gameImageClass={styles.participate_game_img}
									dataAos={{
										"data-aos": "zoom-in",
										"data-aos-delay": "100",
										"data-aos-offset": "-100"
									}}
									gameImage={item?.image}
									gameTitle={item?.name}
									item={item}
									// playCard={true}
									// onPlayClick={() =>
									//   item?.name.toLowerCase() === "ram mandir ka nirman"
									//     ? onPlayClick(item)
									//     : onOnotherPlayClick(item)
									// }
									titleClassName={styles.participate_game_title}
									hideButtons
									silverCard
									glowing={item?.name === "Mandir darshan"}
								/>
							))
						) : (
							<h3 className={styles.no_types_avail}>No collectible types available</h3>
						)}
					</Slider>
					<p className={styles.under_txt}>
						once you have completed the mandir nirmaan activity, you may directly proceed to the mandir darshan activity or engage in other optional activities.
					</p>
				</Container>
			</section>
			{showVideo && (
				<GamePlayBefore
					backButton={true}
					showButton={false}
					onEnded={() => setShowVideo(false)}
					handleClose={() => setShowVideo(false)}
				/>
			)}

			{show && (
				<GamePlayVideo
					showButton={true}
					video={imageUrl}
					onEnded={() => setShow(false)}
					handleClose={() => setShow(false)}
				/>
			)}
			{showgamplay && (
				// <GameplayCinematic
				// 	isLoading={isLoading}
				// 	show={showgamplay}
				// 	gamePlay={() => setShowMode(true)}
				// 	handleClose={() => setShowGamplay(false)}
				// />
				<GamePlayCinematicReact
				show={showgamplay}
			 	subtitles={subtitles}
				gamePlay={() => setShowMode(true)}
                videoUrl={S3_BUCKET.CINEMATIC_GAME}
				handleClose={() => setShowGamplay(false)}
				/>
			)}
			<SelectMode
				show={showMode}
				handleClose={() => setShowMode(false)}
				itemToVerify={itemToVerify}
			/>

		</>
	);
};

export default Participation;
