import { Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiCallGet, apiCallPost } from "../../../axiosApi/Axios";
import { setLoader } from "../../../redux/loader";
import { S3_BUCKET, S3_BUCKET_AUDIO } from "../../../utils/Constants";
import Button from "../../common/Buttons/Button/Button";
import Loader from "../../common/Loader/Loader";
import styles from "./QuesPage.module.scss";
import SoundButton from "../../common/SoundButton/SoundButton";

const QuesPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [showContent, setshowContent] = useState(false);
	const userId = useSelector((state) => state.userDetails.userId);
	const userDetails = useSelector((state) => state.userDetails.newUserId);
	const userData = useSelector((state) => state.userDetails);
	const [quiz, setQuiz] = useState([]);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
	const [message, setMessage] = useState(null);
	const audioRef = useRef();
	const [correctAnswer, setCorrectAnswer] = useState("");
	const [isMuted, setIsMuted] = useState(false)
	const [isSubmitted, setIsSubmitted] = useState(false);
	const hasMounted = useRef(false);


	const handleMuteToggle = (isMuted) => {
		setIsMuted(isMuted)
	};

	// const userId = "05b85ca0-6629-466f-a21a-cd43dec4ef3e";
	// let collectiveId = "b3fa4a79-f947-47e0-8784-108f103df32c";
	// userData?.collectiveId

	const getQuiz = async () => {
		try {
			let response = await apiCallGet(`/api/v1/satyug/users/question/${userData?.collectiveId}/answer`);

			if (response.status === 200) {
				setQuiz(response?.data);

				// Check if userDetails?.refer is not null or undefined before saving to localStorage
				if (userData?.refer) {
					localStorage.setItem("referId", userData?.refer);
				} else {
					console.error("userDetails?.refer is null or undefined");
				}
			} else {
				navigate("/participation");
			}
		} catch (error) {
			console.error(error);
		}
	};


	useEffect(() => {
		const fetchData = async () => {
			if (userDetails === null && userId === null) {
				navigate("/participation");
			} else {
				if (!hasMounted.current) {
					hasMounted.current = true;
					await getQuiz();
				}
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDetails, userId]);

	useEffect(() => {
		if (userDetails || userId) {
			const audio =
				audioRef?.current && audioRef.current.play();
		}

	}, [userDetails, userId, audioRef?.current]);

	useEffect(() => {
		setTimeout(() => {
			setshowContent(true);
		}, 1500);
	}, []);

	useEffect(() => {
		setMessage(null);
		if (currentQuestionIndex >= 0 && currentQuestionIndex < quiz.length) {
			setCurrentQuestionIndex(currentQuestionIndex);
		}
	}, [currentQuestionIndex, quiz]);

	useEffect(() => {
		if (message === false) {
			const timer = setTimeout(() => {
				setMessage(null);
			}, 8000);

			return () => clearTimeout(timer);
		}
	}, [message]);

	const onSubmit = async (values, { resetForm }) => {
		// const currentQuestion = quiz[currentQuestionIndex];
		const data = {
			question: quiz?.id,
			answer: values.answer?.toUpperCase(),
			userId: userId,
		};
		setIsMuted(true)
		try {
			let response = await apiCallPost(
				"/api/v1/satyug/users/validate-quiz-answer",
				data,
				{},
				false,
				false,
				false,
			);
			if (response.status === 200) {
				setCorrectAnswer(response.data);
				setIsAnswerCorrect(true);
				setIsSubmitted(true);
				setTimeout(() => {
					setIsAnswerCorrect(null);
					setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
					resetForm();
					window.scrollTo(0, 100000000)
					// if (currentQuestionIndex >= quiz.length - 1) {
					navigate("/question-right");
					dispatch(setLoader(false));
					// }
				}, 10000);
			} else {
				setCorrectAnswer(response?.data);
				setIsSubmitted(true);
				setMessage(false);
				setTimeout(() => {
					navigate("/share");
					dispatch(setLoader(false));
				}, 8000);
			}
		} catch (error) {
			dispatch(setLoader(false));
			console.log(error);
			navigate("/share");
		}
	};

	const currentQuestion = quiz[currentQuestionIndex];
	return (
		<>
			<audio muted={isMuted} ref={audioRef} loop>
				<source src={S3_BUCKET_AUDIO.QUIZ} type="audio/mpeg" />
			</audio>
			<SoundButton onMuteToggle={handleMuteToggle} />
			{!quiz || quiz.length === 0 ? (
				<Loader />
			) : (
				<>
					<img
						src={`${S3_BUCKET.IMAGES}/question_page.jpg`}
						alt="shri-ram-ji"
						className={`${!showContent ? styles.show : ""} ${styles.mainImg}`}
					/>

					<section
						className={`${styles.ques_page} ${showContent ? styles.show : ""}`}
					>
						<Container>
							<div className={styles.ques_page_inner}>
								{/* <img
                  data-aos="zoom-in"
                  src={`${S3_BUCKET.IMAGES}/archery.png`}
                  className={styles.archery_icon}
                  alt="archery-icon"
                /> */}
								<img
									data-aos="zoom-in"
									src={`${S3_BUCKET.DIGITAL_LOGO}`}
									className={styles.archery_icon}
									alt="archery-icon"
								/>
								<h1>
								Answer this question easily and get a chance to win the golden trophy.
								</h1>
								<h2>{quiz?.question}</h2>
								<Formik
									initialValues={{
										answer: "",
									}}
									onSubmit={onSubmit}
								>
									{(formik) => {
										return (
											<Form>
												<ul>

													{Object.entries(quiz?.options).map(
														([key, option]) => {
															return (
																<li key={key}>
																	<label className={`${isSubmitted ? styles.submitted : ""} ${correctAnswer === option ? styles.active : ""}`} htmlFor={`radio_${key}`}>
																		<Field
																			type="radio"
																			name="answer"
																			value={key}
																			id={`radio_${key}`}
																		/>
																		<span className={styles.radio_btn} />
																		<span>{option}</span>
																	</label>
																</li>
															)
														}
													)}
												</ul>
												<Button
													className={styles.submit_action}
													type="submit"
													disabled={!formik.dirty || isSubmitted}
													text="Submit"
												/>
											</Form>
										);
									}}
								</Formik>
								{isAnswerCorrect === true && (
									<p className={styles.correct_answer}>
										{/* Correct answer! */}
										This is the correct answer. Your knowledge about our culture is impeccable. I am emailing you 1 Leadership point and. Also, you can check your Karma Points aur Leadership Points on <a href={`https://www.activity.digitalrammandir.com/collectibles/?id=${userId || userDetails}`} target={"_blank"} rel="noreferrer">https://www.activity.digitalrammandir.com/collectibles</a>
									</p>
								)}
								{message === false && (
									<p className={styles.wrong_answer}>
										{/* Wrong answer! */}
										This is wrong answer. You should know more about Raghuvanshi Lord Ram Ji. Also, don't forget to check your Karma Points and Leadership Points on <a href={`https://www.activity.digitalrammandir.com/collectibles/?id=${userId || userDetails}`} target={"_blank"} rel="noreferrer">https://www.activity.digitalrammandir.com/collectibles</a>
									</p>
								)}
							</div>
						</Container>
					</section>
				</>
			)}
		</>
	);
};

export default QuesPage;
