import React, { useState, useEffect } from 'react'; // Import useEffect
import styles from "./BrickAsset.module.scss";
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiCallGet } from '../../../axiosApi/Axios';
import { S3_BUCKET } from '../../../utils/Constants';
import SuccessModal from '../../common/Modals/SuccessModal/SuccessModal';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../../hooks/useQuery';
import { setUserId } from '../../../redux/userDetails/userSlice';
import Header from '../../common/Header/Header';
import Button from '../../common/Buttons/Button/Button';

const BrickAsset = () => {
  const location = useLocation();
  const email = useSelector((state) => state.userDetails.email);
  const [showModal, setShowModal] = useState(false);
  const { success } = location?.state || {};
  const dispatch = useDispatch()

  const query = useQuery();
  const userId = query.get("id")

  useEffect(() => {
    if (success) {
      setShowModal(true);
    }
  }, [success]);


  const handleCloseModal = () => {
    window.history.replaceState({}, document.success)
    setShowModal(false);
  };



  const getProductDetails = async (assetName) => {
    try {
      let response = await apiCallGet(
        `/api/v1/satyug/product/${assetName}/10/1`,
        {},
        true,
        true
      );

      if (response.status === 200) {
        let payload = {
          emailUserId: query.get("id"),
        };
        dispatch(setUserId(payload));
        navigate('/view-asset', {
          state: {
            productDetails: response,
            isProduct: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  return (
    <>
      <section className={styles.brick}>
        <Container>
          <img className={styles.logo} src={S3_BUCKET.DIGITAL_LOGO} alt="digital-ram-mandir" />
          {/* <h2>₹ 1000</h2> */}
          <Row className={"justify-content-center"}>
            <Col lg={6} xs={8}>
              <div className={`${styles.asset_box}`}>
                <div className={styles.asset_box_inner}>
                  <div className={styles.asset_img}>
                    {/* <p>₹ 1000</p> */}
                    <img src={S3_BUCKET.BRICK} alt="shirt" />
                  </div>
                  <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
                  <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
                </div>
                {/* <h3>Brick</h3> */}
                <Button onClick={() => getProductDetails('brick')} className={styles.buy_btn}>Buy Brick</Button>
              </div>
            </Col>
          </Row>
        </Container>
        <SuccessModal
          show={showModal}
          handleClose={() => {
            handleCloseModal();
          }}
          email={email}
        />
      </section>
    </>
  )
}

export default BrickAsset;
