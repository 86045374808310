import React from 'react'
import { Modal } from "react-bootstrap";
import styles from "./WelcomeToSiteModal.module.scss";
import { S3_BUCKET } from '../../../../utils/Constants';
import Button from '../../Buttons/Button/Button';

const WelcomeToSiteModal = ({ show, handleClose, handleModal, setIsPlaying }) => {
    const handlePlay = () => {
        setIsPlaying(true)
    }
    return (
        <>
            <Modal
                centered
                show={show}
                onHide={handleClose}
                className={`${styles.welcome}`}
                backdrop="static"
                keyboard={true}
            >
                <img className={styles.bow} src={S3_BUCKET.UP_BOW} alt="bow" />
                <img className={styles.flag} src={S3_BUCKET.WELCOME_FLAG} alt="jai-shri-ram-flag" />
                <div className={styles.title}>
                    <img src={S3_BUCKET.WELCOME_LEFT_ARROW} alt="left-arrow" />
                    <h2>
                    One slogan, One name <br /> Jai Shri Ram
                    </h2>
                    <img src={S3_BUCKET.WELCOME_RIGHT_ARROW} alt="left-arrow" />
                </div>
                <Button onClick={() => {
                    handleModal();
                    handleClose();
                    handlePlay();
                }} className={styles.enter_btn}
                    variant={"secondary-btn"}
                >Click to Enter</Button>
            </Modal>
        </>
    )
}

export default WelcomeToSiteModal
