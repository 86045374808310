import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import animation from "../../../assets/animations/fuljhadia.json";
import { apiCallGet, apiCallPost } from "../../../axiosApi/Axios";
import { useQuery } from "../../../hooks/useQuery";
import {
  setUserDetails,
  setUserEmail,
  setUserId,
  setUserReferKey
} from "../../../redux/userDetails/userSlice";
import {
  FORMIK_REGEX,
  GAME_TOKEN,
  S3_BUCKET
} from "../../../utils/Constants";
import SecondaryBtn from "../../common/Buttons/SecondaryBtn/SecondaryBtn";
import FormControl from "../../common/Formik/FormControl";
import PhoneInput from "../../common/Formik/PhoneInput/PhoneInput";
import Loader from "../../common/Loader/Loader";
import LottieAnimation from "../../common/LottieAnimation/LottieAnimation";
import SelectedCollectible from "../../common/Modals/SelectedCollectible/SelectedCollectible";
import ContentLayout from "../../layouts/ContentLayout/ContentLayout";
import styles from "./Login.module.scss";
import SoundButton from "../../common/SoundButton/SoundButton";
import Cookies from "js-cookie";

const Login = () => {
  const initialValues = {
    name: "",
    whatsapp: "",
    emailId: "",
  };
  const query = useQuery();
  const dispatch = useDispatch();
  const [played, setPlayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const userDetails = useSelector((state) => state.userDetails);
  const loggedIn = useSelector((state) => state.userDetails.token);
  const [isMuted, setIsMuted] = useState(false)
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const videoRef = useRef();

  useEffect(() => {
    const state = { page: "participation" };
    window.history.pushState(state, "", "");

    const handlePopState = (event) => {
      if (event.state && event.state.page === "participation") {
        navigate("/participation");
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);


  useEffect(() => {
    if (!loggedIn) {
      if (query.get("token")) {
        (async () => {
          try {
            let response = await apiCallPost(
              "/api/v1/satyug/users/validate-token",
              {
                id: query.get("token"),
                type: GAME_TOKEN.GameVerifyToken,
              }
            );
            if (response.status === 200) {
              let payload = {
                collectiveId: query.get("collectiveId"),
                token: response.data.token,
                newUserId: query.get("userId"),
                newType: query.get("type")
              };
              dispatch(setUserDetails(payload));
              navigate("/login")
              setShow(true)
            } else {
              localStorage.clear();
              navigate("/participation")
            }
          } catch (e) {
            navigate("/participation")
          }
        })();
      } else {
        navigate("/participation")
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let intervalId;
    if (played) {
      intervalId = setInterval(playAudioInterval, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [played]);

  function playAudio() {
    if (!played) {
      if (videoRef?.current) {
        videoRef.current.play();
        setPlayed(true);
      }
    }
  }

  // function playAudio() {
  //   if (!played) {
  //     const sound = new Howl({
  //       src: [S3_BUCKET_AUDIO.AFTER_GAME],
  //       loop: true,
  //     });

  //     sound.play();
  //     setPlayed(true);
  //   }
  // }


  const userDetail = async () => {
    // setIsLoading(true);
    try {
      let response = await apiCallGet(`api/v1/satyug/users/profile/${userDetails?.newUserId}/details`, {

      });
      if (response.status === 200) {
        if (userDetails?.newUserId) {
          onSubmit(response?.data)
        }
      }
    } catch (e) {
      // setIsValidated(false);
    }
  };


  function playAudioInterval() {
    playAudio();
  }


  function playAudioInterval() {
    if (videoRef?.current) {
      videoRef.current.play();
    }
  }

  const emailType = Cookies.get("googtrans") === "undefined" ? "/en/en" : Cookies.get("googtrans")

  const onSubmit = async (values) => {
    setIsMuted(true)
    setIsLoading(true)
    let cleanedPhoneNumber;
    if (!userDetails?.newUserId) {
      cleanedPhoneNumber = values.whatsapp.replace("+", "");
    }
    let data;
    if (userDetails?.newUserId) {
      data = {
        name: values?.name,
        phoneNumber: values?.phoneNumber,
        email: values?.email,
        collectiveId: [userDetails?.collectiveId],
        token: userDetails?.token,
        lang: emailType.split('/').pop()
      };
    } else {
      data = {
        name: values.name,
        phoneNumber: cleanedPhoneNumber,
        email: values.emailId,
        collectiveId: (userDetails?.collectiveId).split(","),
        token: userDetails.token,
        lang: emailType.split('/').pop()
      };
    }
    try {
      // if(userDetails?.newUserId){
      // dispatch(setLoader(true));
      // }
      let response = await apiCallPost(
        "/api/v1/satyug/users/collective",
        data,
        {},
        userDetails?.newUserId ? false : true,
        userDetails?.newUserId ? false : true,
      );
      // if(userDetails?.newUserId){
      // dispatch(setLoader(false));
      // }
      setIsLoading(false)
      if (response.status === 200) {
        dispatch(setUserId(response.data.userId));
        dispatch(setUserReferKey(response.data.userKey));

        if (!userDetails?.newUserId) {
          navigate("/question");
        } else {
          navigate("/points")
        }
      } else {
        setIsLoading(false)
        setIsMuted(false)

      }
    } catch (error) {
      console.log(error);
      setIsMuted(false)
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .max(
        40,
        "Name must be between 2-40 characters, only alphabets are allowed."
      )
      .min(
        2,
        "Name must be between 2-40 characters, only alphabets are allowed."
      )
      .matches(FORMIK_REGEX.ALPHA_REGEX, "Only alphabets are allowed."),
    whatsapp: Yup.string()
      .required("WhatsApp number is required")
      .matches(
        FORMIK_REGEX.MOBILE_NUMBER_REGEX,
        "WhatsApp number must be between 5-16 numbers"
      )
      .min(5, "WhatsApp number must be between 5-16 numbers")
      .max(16, "WhatsApp number must be between 5-16 numbers"),

    emailId: Yup.string()
      .required("Email is required")
      .matches(FORMIK_REGEX.EMAIL_REGEX, "Enter valid email address"),
  });


  const handleMuteToggle = (isMuted) => {
    setIsMuted(isMuted)
  };

  const handlePause = () => {
    if (videoRef.current) {
      // Pause the video immediately
      videoRef.current.pause();
      setPlayed(false);

      // Set a timeout to resume after 15 seconds
      setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.play();
        }
      }, 150000); // 15 seconds in milliseconds
    }
  }



  return (

    <>
      <SoundButton onMuteToggle={handleMuteToggle} />
      {userDetails?.newUserId ? (
        <Loader />
      ) : (
        <>
          <ContentLayout videoRef={videoRef} isMuted={isMuted} className={styles.login_page}>
            {/* <img
          data-aos="fade-up"
          className={styles.archery_icon}
          src={`${S3_BUCKET.IMAGES}/archery.png`}
          alt="archery"
        />
        <HeadingText heading="Satyug" className={styles.logo} /> */}
            <img
              data-aos="fade-up"
              className={styles.archery_icon}
              src={`${S3_BUCKET.IMAGESLOGO}`}
              alt="archery"
            />
            <LottieAnimation className={styles.animation} animation={animation} />
            {/* <audio ref={audioRef} loop>
          <source src={S3_BUCKET_AUDIO.AFTER_GAME} type="audio/mpeg" />
        </audio> */}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form>
                    <FormControl
                      className={styles.input}
                      label="Name :"
                      formik={formik}
                      name="name"
                      placeholder="Enter Your Name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    <PhoneInput
                      id="whatsapp"
                      international
                      control="input"
                      className={styles.input}
                      name="whatsapp"
                      label="WhatsApp :"
                      formik={formik}
                      defaultCountry="IN"
                      onChange={(value) => {
                        formik.setFieldValue("whatsapp", value);
                      }}
                    />
                    <FormControl
                      className={styles.input}
                      type="email"
                      placeholder="Example@email.com"
                      label="Email ID :"
                      name="emailId"
                      formik={formik}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.emailId}
                    />
                    <SecondaryBtn onClick={handlePause} loading={isLoading} type="submit" text="Submit" />
                    <p className={styles.disclaimer}>*We are GDPR compliant and your data is safe.</p>
                  </Form>
                );
              }}
            </Formik>
          </ContentLayout>
        </>
      )}

      <SelectedCollectible
        play={playAudio}
        userDetail={userDetail}
        userDetails={userDetails}
        show={show}
        handleClose={() => setShow(false)}
      />
    </>

  );
};

export default Login;