import React from "react";
import Button from "../../Buttons/Button/Button";
import CommonModal from "../CommonModal/CommonModal";
import styles from "./AudioModal.module.scss";

const AudioModal = ({ show, handleClose, videoRef }) => {

	const play = () => {
		handleClose();
		videoRef();
	};

	return (
		<>
			<CommonModal
				show={show}
				handleClose={handleClose}
				keyboard={false}
				backdropClassName={"audio_modal_backdrop"}
				className={`${styles.audio_modal}`}
				title="Need Audio Access"
				backdrop="static"
			>
				<Button
					autoFocus
					text="Allow"
					onClick={play}
					variant="secondary-btn"
					className={styles.continue_btn}
				/>
			</CommonModal>
		</>
	);
};

export default AudioModal;
