import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentLayout from '../../layouts/ContentLayout/ContentLayout';
import styles from './AfterGameEnd.module.scss';
import { S3_BUCKET, getBrowserType } from '../../../utils/Constants';
import { useSelector } from 'react-redux';

const AfterGameEnd = () => {
  const navigate = useNavigate();
  const videoRef = useRef();
  const userDetails = useSelector((state) => state.userDetails);
  const browserType = getBrowserType()

  const playVideo = (type) => {
    let videoSource = '';
    switch (type) {
      case "1":
        videoSource = browserType === "Safari" ? S3_BUCKET.PRABHU_SHINGAR_SAFARI : S3_BUCKET.PRABHU_SHINGAR;
        break;
      case "2":
        videoSource = browserType === "Safari" ? S3_BUCKET.BAGICHA_NIRMAN_SAFARI : S3_BUCKET.BAGICHA_NIRMAN;
        break;
      case "3":
        videoSource = browserType === "Safari" ? S3_BUCKET.BHOJNALYA_YOGDAN_SAFARI : S3_BUCKET.BHOJNALYA_YOGDAN;
        break;
      default:
        videoSource = 'path_to_default_video.mp4';
    }

    if (videoRef.current) {
      videoRef.current.src = videoSource;
      videoRef.current.play();
    }

    return videoSource;  // Return the video source
  };

  const videoSource = playVideo(userDetails?.newType);


  return (
    <>
      <div className={styles.question_right}>
        <video
          onEnded={() => navigate('/question')}
          ref={videoRef}
          autoPlay
        >
          {/* Empty source tag, the source will be set dynamically */}
          <source src={videoSource} type="video/mp4" />
        </video>
        <button onClick={() => navigate("/question")} className={styles.skip_btn}>
          Skip
        </button>
      </div>
    </>
  );
};

export default AfterGameEnd;
