import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'


const VideoSkip = () => {
    const [count, setClickCount] = useState(0);
    const handleClick = () => setClickCount(count => count + 1)
  return (
    <>
        <Outlet context={{count , handleClick, }}/>
    </>
  )
}

export default VideoSkip