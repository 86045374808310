import React, { useState, useEffect } from 'react'; // Import useEffect
import Slider from "react-slick";
import styles from "./SelectAsset.module.scss";
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiCallGet } from '../../../axiosApi/Axios';
import { S3_BUCKET } from '../../../utils/Constants';
import SuccessModal from '../../common/Modals/SuccessModal/SuccessModal';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../../hooks/useQuery';
import { setUserId } from '../../../redux/userDetails/userSlice';
import Loader from '../../common/Loader/Loader';

const SelectAsset = () => {
  const location = useLocation();
  const email = useSelector((state) => state.userDetails.email);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false); 
  const { success } = location?.state || {};
  const dispatch = useDispatch()

  const query = useQuery();

  useEffect(() => {
    if (success) {
      setShowModal(true);
    }
  }, [success]);


  const handleCloseModal = () => {
    window.history.replaceState({}, document.success)
    setShowModal(false);
  };



  const getProductDetails = async (assetName) => {
    setLoading(true)
    try {
      let response = await apiCallGet(
        `/api/v1/satyug/product/${assetName}/10/1`,
        {},
        true,
        true
      );
      setLoading(false)
      if (response.status === 200) {
        let payload = {
          emailUserId: query.get("id"),
        };
        dispatch(setUserId(payload));
        navigate('/view-asset', {
          state: {
            productDetails: response,
            isProduct: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  return (
    <>{loading ? <Loader/> :
    <section className={styles.select_asset}>
      <Container>
        <h2>Select type of asset you want to receive </h2>
        <Slider infinite={false} slidesToShow={5} responsive={[{
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
          }
        }]}>
          <div onClick={() => getProductDetails('t-shirt')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                {/* <p>₹ 350</p> */}
                <img src={S3_BUCKET.SHIRT} alt="shirt" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Jai Shri Ram T-SHIRT</h3>
          </div>

          <div onClick={() => getProductDetails('momento')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                <img src={S3_BUCKET.MOMENTO_BOX} alt="momento" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Digital Ram Mandir Momento Box</h3>
          </div>

          <div onClick={() => getProductDetails('Cap')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                {/* <p>₹ 175</p> */}
                <img src={S3_BUCKET.CAP} alt="cap" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Jai Shri Ram Cap</h3>
          </div>

          <div onClick={() => getProductDetails('photoFrame')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                {/* <p>₹ 175</p> */}
                <img src={S3_BUCKET.MOMENTO_WALL_FRAME} alt="photoFrame" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Digital Ram Mandir Momento Wall Frame</h3>
          </div>
          
          <div onClick={() => getProductDetails('brick')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                {/* <p>₹ 175</p> */}
                <img src={S3_BUCKET.BRICK} alt="cap" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Jai Shri Ram Brick</h3>
          </div>
        </Slider>
      </Container>
      <SuccessModal
        show={showModal}
        handleClose={() => {
          handleCloseModal();
        }}
        email={email}
      />
    </section>
 }
    </>
  )
}

export default SelectAsset;
