import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import animation from "../../../assets/animations/fuljhadia.json";
import {
  FacebookIcon,
  TelegramIcon2,
  TwitterIcon,
  WhatsappIcon
} from "../../../assets/svg/svgicons";
import { apiCallPost } from "../../../axiosApi/Axios";
import { useQuery } from "../../../hooks/useQuery";
import { setLoader } from "../../../redux/loader";
import { ENVIRONMENT, NETWORK_SPEED, S3_BUCKET } from "../../../utils/Constants";
import Button from "../../common/Buttons/Button/Button";
import HeadingText from "../../common/HeadingText/HeadingText";
import LottieAnimation from "../../common/LottieAnimation/LottieAnimation";
import AudioModal from "../../common/Modals/AudioModal/AudioModal";
import ContentLayout from "../../layouts/ContentLayout/ContentLayout";
import styles from "./Sharepage.module.scss";
import SoundButton from "../../common/SoundButton/SoundButton";
import Cookies from "js-cookie";
import AfterShare from "../../common/Modals/AfterShare/AfterShare";

const SharePage = () => {
  const query = useQuery();
   const userId = query.get("userId");
   const selectedType = query.get("type")
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const referKey = useSelector((state) => state.userDetails.refer);
  const userDetails = useSelector((state) => state.userDetails);
  const videoRef = useRef();
  const audioRef = useRef();
  const [show, setShow] = useState(false)
  const [apiData , setApiData] = useState(false);
  const [afterShare , setAfterShare] = useState(false);
  const [isShareWindowOpen, setIsShareWindowOpen] = useState();
  const [selectedShareType, setSelectedShareType] = useState(null);
  const { count, handleClick } = useOutletContext();
  const referId = localStorage.getItem("referId")
  const emailType = Cookies.get("googtrans")  === "undefined" ? "/en/en" : Cookies.get("googtrans")
  const [isMuted, setIsMuted] = useState(false)

  const handleShareWindowClose = () => {
    setIsShareWindowOpen(false);
  };
//   useEffect(()=> {
//   setTimeout(()=>{
//     if(apiData){
//     setAfterShare(false)
//     }
//   },5000)
// },[])

  useEffect(()=>{
      if(apiData){
    setAfterShare(selectedType === "vvip" && apiData?.count === 1 ? true : false)
      }
  },[apiData])
  

  function playAudio() {
    videoRef?.current && videoRef?.current?.play();
  }

  useEffect(() => {
    if (userId) {
      // playAudio();
    } else {
      videoRef?.current && videoRef?.current?.play();
    }

  }, [userId, videoRef?.current]);

  const urlLinks1 = ENVIRONMENT.URL_LINK + referKey
  const urlLinks2 = ENVIRONMENT.URL_LINK + referId
  const ShareGameId = ENVIRONMENT.URL_LINK + userId
  const urlLinks = userId ? ShareGameId :  !urlLinks1 ? urlLinks1 : urlLinks2

  const speed = useSelector((state) => state.network.speed);

  let imageUrl = useMemo(() => {
    switch (speed) {
      case NETWORK_SPEED.LOW:
        return S3_BUCKET.SHARE_360;
      case NETWORK_SPEED.MEDIUM:
        return S3_BUCKET.SHARE_480;
      case NETWORK_SPEED.HIGH:
        return S3_BUCKET.SHARE_BACK;
      default:
        return S3_BUCKET.SHARE_360;
    }
  }, [speed]);

  const getCount = async () => {
    const payload = {
      id: userId,
      type: selectedShareType,
      lang : emailType.split('/').pop(),
      
    };
    dispatch(setLoader(true))
    try {
      let response = await apiCallPost(
        `/api/v1/satyug/referral`,
        payload,
        {},
        false,
        false
      );
      dispatch(setLoader(false))
      if (response.status === 200) {   
      setApiData(response?.data)
      setIsShareWindowOpen("")
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    if(selectedType === "vvip" && apiData?.count === 2 ){
      window?.close();
      }else if(selectedType === "vip" && apiData?.count === 1) {
      window?.close();
      }
  },[apiData])

   const getKarmaPoints = async () => {
        try {
          await apiCallPost("api/v1/satyug/users/karmaPoints", {
            id: userId,
            type: "shareGame",
            lang : emailType.split('/').pop(),
          });
        } catch (error) { }
    };
  
    
  useEffect(() => {
    if (isShareWindowOpen === false && userId) {
      getCount();
      getKarmaPoints();
    }
  }, [isShareWindowOpen])

  const handleMuteToggle = (isMuted) => {
    setIsMuted(isMuted)
  };
    
  useEffect(()=>{
    if(!userId && isShareWindowOpen === false ){
      navigate('/participation')
    }
  })
  
  const handleClose = () => {
    setAfterShare(false);
  };


  return (
    <ContentLayout
      // rightContentClass={styles.share_right}
      rightContentClass={"d-none"}
      className={styles.share_page}
      hide={true}
      image={S3_BUCKET.SHARE_IMAGE}
      leftContentClass={"mx-auto"}
    >
      {userId ? "" :
        <SoundButton onMuteToggle={handleMuteToggle} />
      }

      <video muted={isMuted} ref={videoRef} playsInline className={styles.share_page_video} loop>
        <source src={userId ? '' : imageUrl} type="video/mp4" />
      </video>

      <img
        src={`${S3_BUCKET.DIGITAL_LOGO}`}
        alt="logo-icon"
        className={styles.logo}
      />
      <HeadingText className={styles.heading} heading="Share" />
      <LottieAnimation className={styles.animation} animation={animation} />
      <ul>
        <li>
          <WhatsappShareButton
            url={urlLinks}
            onShareWindowClose={() => {
              handleShareWindowClose();
              setSelectedShareType(3);
            }}
          >
            <Link
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsappIcon size={32} round
                onClick={() => setIsShareWindowOpen(true)}
              />
            </Link>
          </WhatsappShareButton>
        </li>
        <li>
          <FacebookShareButton
            url={urlLinks}
            onShareWindowClose={() => {
              handleShareWindowClose();
              setSelectedShareType(1);
            }}

          >
            <Link className={styles.fb} target="_blank" rel="noreferrer">
              <FacebookIcon
                onClick={() => setIsShareWindowOpen(true)}
              />
            </Link>
          </FacebookShareButton>
        </li>
        <li>
          <TelegramShareButton
            url={urlLinks}
            onShareWindowClose={() => {
              handleShareWindowClose();
              setSelectedShareType(5);
            }}
          >
            <Link target="_blank" rel="noreferrer">
              <TelegramIcon2
                onClick={() => setIsShareWindowOpen(true)}
              />
            </Link>
          </TelegramShareButton>
        </li>
        <li>
          <TwitterShareButton
            url={urlLinks}
            onShareWindowClose={() => {
              handleShareWindowClose();
              setSelectedShareType(4);
            }}
          >
            <Link target="_blank" rel="noreferrer">
              <TwitterIcon
                onClick={() => setIsShareWindowOpen(true)}
              />
            </Link>
          </TwitterShareButton>
        </li>
      </ul>
          
      {userId && selectedShareType === "vvip" && apiData?.count === "2" ? 
        ""
         : ""
        }
      {userId ? (
        ""
      ) : (
        <></>
      )}
      <AudioModal
        show={show}
        handleClose={() => setShow(false)}
        videoRef={playAudio}
      />
      {userId ? "":
        <button onClick={() => {
          handleClick();
          if (count === 1) {
            navigate("/participation");
          } else {
            navigate("/participation");
            userDetails?.newType === null ? navigate("/skip-video")  : navigate("/participation")
          }
        }} className={`${styles.back_btn} ${styles.skip_btn}`} >Skip</button>}
        <AfterShare
        show = {afterShare}
        handleClose ={handleClose} 
        />

    </ContentLayout>
      
  );
};

export default SharePage;
