import React, { useState } from "react";
import styles from "./Backbutton.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { S3_BUCKET } from "../../../utils/Constants";
import DonationModal from "../Modals/DonationModal/DonationModal";
import { CoinIcons } from "../../../assets/svg/svgicons";
import DonationDetailsModal from "../Modals/DonationDetailsModal/DonationDetailsModal";

const Backbutton = ({ className }) => {
  const [show, setShow] = useState(false);
  const [disShow, setDisShow] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const disModal = () => {
    setDisShow(true)
    setShow(false)
  }

  return (
    <>
      <div className={`${styles.back_wrap} ${className || ""}`}>
        {location?.pathname === "/participation" ? (
          <button onClick={() => window.location.href = 'https://www.digitalrammandir.com'} className={styles.back_btn}>
            <img src={`${S3_BUCKET.IMAGES}/back-icon.png`} alt="back-icon" />
            Back
          </button>
        ) :
          location?.pathname === "/view-asset" ?
            <button onClick={() => navigate(-1)} className={styles.back_btn}>
              <img src={`${S3_BUCKET.IMAGES}/back-icon.png`} alt="back-icon" />
              Back
            </button>
            :
            (
              <button onClick={() => navigate("/participation")} className={styles.back_btn}>
                <img src={`${S3_BUCKET.IMAGES}/back-icon.png`} alt="back-icon" />
                Back
              </button>
            )}

        {location.pathname === "/participation" && (
          <button className={styles.donate_btn} onClick={() => setShow(true)}>
            <CoinIcons />
            Donate
          </button>
        )}
      </div>
      {show && <DonationModal show={show} handleShowDetails={disModal} handleClose={() => setShow(false)} />}
      <DonationDetailsModal
        show={disShow}
        handleClose={() => { setDisShow(false); setShow(true) }}
      />
    </>
  );
};

export default Backbutton;
