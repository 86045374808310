import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from "./SkipShare.module.scss";
import { S3_BUCKET, getBrowserType } from '../../../utils/Constants';

const SkipShareVideo = () => {
	const navigate = useNavigate()
	const browserType = getBrowserType()
	const onVideoEnd = () => {
		navigate("/share");
	}
	return (
		<>
			<section className={styles.skip_video}>
				<video playsInline autoPlay onEnded={onVideoEnd}>
					{browserType === "Safari" ?
						<source src={S3_BUCKET.BEFORE_SHARE_END_SAFARI} type="video/mp4" />
						:
						<source src={S3_BUCKET.BEFORE_SHARE_END} type="video/mp4" />
					}
				</video>
			</section>
		</>
	)
}

export default SkipShareVideo