import React from 'react'
import { Modal } from "react-bootstrap";
import styles from "./DonationDetailsModal.module.scss";
import Button from '../../Buttons/Button/Button';

const DonationDetailsModal = ({ show, handleClose }) => {
    const bankDetails = {
        name: "Shri Ram Janmbhoomi Teerth Kshetra",
        number: "42162875158",
        ifsc: "SBIN0000691",
        swiftCode: "SBININBB104",
        branch: "New Delhi Main Branch, 4th Floor, FCRA Cell1, Sansad Marg, New Delhi - 110001",
    }
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className={`${styles.details} common_modal`}
                centered
            >
                <Modal.Header className='justify-content-center'>
                    <h3>Disclaimer</h3>
                </Modal.Header>
                <Modal.Body>
                    <h4>Contribute voluntarily to Shri Ram Janmbhoomi Teerth Kshetra</h4>
                    <ul>
                        <li>
                            <h5>A/c Name :</h5>
                            <p>{bankDetails.name}</p>
                        </li>
                        <li>
                            <h5>A/c No :</h5>
                            <p>{bankDetails.number}</p>
                        </li>
                        <li>
                            <h5>IFSC Code :</h5>
                            <p>{bankDetails.ifsc}</p>
                        </li>
                        <li>
                            <h5>SWIFT Code :</h5>
                            <p>{bankDetails.swiftCode}</p>
                        </li>
                        <li>
                            <h5>Branch :</h5>
                            <p>{bankDetails.branch}</p>
                        </li>
                    </ul>
                    <Button onClick={handleClose} className={"mx-auto d-block"}>Okay</Button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DonationDetailsModal
