// import React, { useEffect } from "react";
// import Cookies from "js-cookie";

// const LanguageTranslator = () => {
//   const googleTranslateElementInit = () => {
//     // Retrieve the selected language from the cookies
//     const lang = localStorage.getItem("userLanguage");


//     // Clear the old googtranss cookie/en/gu
//     Cookies.remove("googtrans");

//     // Set the new googtranss cookie with the selected language
//     Cookies.set("googtrans", lang, { expires: 1 });

//     // Initialize the Google Translate element
//     new window.google.translate.TranslateElement(
//       {
//         pageLanguage: "en",
//         autoDisplay: false
//       },
//       "google_translate_element"
//     );

//     // Hide the language selection dropdown
//     const select = document.querySelector(".goog-te-combo");
//     if (select) {
//       select.style.display = "none";
//     }
//   };

//   useEffect(() => {
//     // Check if the script is already loaded
//     if (!window.googleTranslateElementInit) {
//       // If not loaded, add the script
//       var addScript = document.createElement("script");
//       addScript.setAttribute(
//         "src",
//         "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
//       );
//       document.body.appendChild(addScript);
//       window.googleTranslateElementInit = googleTranslateElementInit;
//     }
//   }, []);

//   return (
//     <>
//       <div id="google_translate_element"></div>
//     </>
//   );
// };

// export default LanguageTranslator;



import React, { useEffect } from "react";
import Cookies from "js-cookie";

const LanguageTranslator = () => {
  const googleTranslateElementInit = () => {
    // Retrieve the selected language from the cookies
    const lang = localStorage.getItem("userLanguage");

    // Clear the old googtranss cookie
    const oldGoogtransCookie = Cookies.get("googtrans");
    if (oldGoogtransCookie) {
      Cookies.remove("googtrans");
    }

    // Set the new googtranss cookie with the selected language
    Cookies.set("googtrans", lang, { expires: 1 });

    // Initialize the Google Translate element
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );

    // Hide the language selection dropdown
    const select = document.querySelector(".goog-te-combo");
    if (select) {
      select.style.display = "none";
    }
  };

  useEffect(() => {
    // Check if the script is already loaded
    if (!window.googleTranslateElementInit) {
      // If not loaded, add the script
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, []);

  return (
    <>
      <div id="google_translate_element"></div>
    </>
  );
};

export default LanguageTranslator;

