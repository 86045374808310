import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import styles from './GamePlayVideoReact.module.scss';
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";

const GamePlayVideoReact = ({ videoUrl, subtitles, onEnded, isPlaying, trackLang }) => {
  const player = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleReady = () => {
    setIsLoading(false);
  };

  const handleBuffer = () => {
    setIsLoading(true);
  };

  return (
    <div className={styles.gameplay_main}>
      {isLoading && (
        <Loader/>
      )}

      <ReactPlayer
        ref={player}
        height={"100%"}
        width={"100%"}
        config={{
          file: {
            attributes: {
              crossOrigin: "anonymous",
            },
            tracks: subtitles.map((subtitle) => ({
              ...subtitle,
              default: trackLang === subtitle?.srcLang,
            })),
          },
        }}
        url={videoUrl}
        playing={isPlaying}
        playsinline
        onReady={handleReady}
        onBuffer={handleBuffer}
        onEnded={onEnded}
      />

      <button
        onClick={() => { navigate('/participation') }}
        className={styles.skip_btn}>
        Skip
      </button>
    </div>
  );
};

export default GamePlayVideoReact;
