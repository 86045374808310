
import React from 'react';
import Button from '../../Buttons/Button/Button';
import styles from "./GamePlayVideo.module.scss";
import { useNavigate } from 'react-router-dom';

const GamePlayVideo = ({ handleClose, video, onEnded, showButton, backButton }) => {
    const navigate = useNavigate();
 
    return (
        <>
            <div
                className={styles.gamplay_modal}
            >
                <video
                    autoPlay
                    playsInline
                    onEnded={onEnded}
                >
                    <source src={video} type="video/mp4" />
                </video>
                {showButton && <button onClick={() => navigate('/participation')} className={`${styles.back_btn} ${styles.skip_btn}`} >Skip</button>}
                {backButton && <Button onClick={() => handleClose()} className={styles.back_btn}>Back</Button>}
            </div>
        </>
    )
}

export default GamePlayVideo