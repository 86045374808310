// import React, { useState } from 'react';
// import styles from "./SoundButton.module.scss";
// import { MutedIcon, SoundIcon } from '../../../assets/svg/svgicons';

// const SoundButton = ({}) => {
//     const [mute, setMute] = useState(false);
//     return (
//         <>
//             <button
//                 className={`${styles.sound} ${mute ? styles.active : ""}`}
//                 onClick={() => setMute(!mute)}
//             >
//                 {
//                     mute ?
//                         <SoundIcon />
//                         :
//                         <MutedIcon />
//                 }
//             </button>
//         </>
//     )
// }

// export default SoundButton




import React, { useState } from 'react';
import styles from "./SoundButton.module.scss";
import { MutedIcon, SoundIcon } from '../../../assets/svg/svgicons';

const SoundButton = ({ onMuteToggle }) => {
  const [mute, setMute] = useState(false);

  const handleToggle = () => {
    const newMuteState = !mute;
    setMute(newMuteState);

    // Call the callback function provided by the parent
    if (onMuteToggle) {
      onMuteToggle(newMuteState);
    }
  };

  return (
    <>
      <button
        className={`${styles.sound} ${!mute ? styles.active : ""}`}
        onClick={handleToggle}
      >
        {mute ?<MutedIcon />  : <SoundIcon />}
      </button>
    </>
  );
};

export default SoundButton;
