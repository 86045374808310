import React from 'react'
import styles from "./CertificateModal.module.scss";
import CommonModal from '../CommonModal/CommonModal';
import Button from '../../Buttons/Button/Button';

const CertificateModal = ({ certificate, show, handleClose ,playAudio ,setIsMuted}) => {
    const hanelClick = () => {
        handleClose();
        playAudio()
        setIsMuted(false)
    }
    return (
        <>
            <CommonModal
                show={show}
                handleClose={handleClose}
                title="Digital Certificate"
                className={styles.certificate}
                backdropClassName={"certificate_backdrop"}
                backdrop="static"
            >
                {
                    certificate ?
                        <img className={styles.certificate_img} src={certificate} alt="certificate" />
                        :
                        <h4>{"धन्यवाद"}</h4>
                }
                <Button variant={"secondary-btn"} className={styles.continue} onClick={hanelClick}>
                    Continue
                </Button>
            </CommonModal>
        </>
    )
}

export default CertificateModal
