import React from 'react';
import styles from "./CommonModal.module.scss";
import { Modal } from "react-bootstrap";
import { S3_BUCKET } from '../../../../utils/Constants';

const CommonModal = ({ show, handleClose, className, title, children, ...rest }) => {
    return (
        <>
            <Modal
                {...rest}
                show={show}
                onHide={handleClose}
                centered
                className={`${styles.modal} ${className || ""}`}
                
            >
                <img className={styles.bow} src={S3_BUCKET.UP_BOW} alt="bow" />
                <Modal.Body>
                    {
                        title &&
                        <h3 className={styles.title}>{title}</h3>
                    }
                    {
                        children
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CommonModal
