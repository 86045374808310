// import React, { useState } from 'react'
// import styles from "./SelectMode.module.scss";
// import { Modal } from "react-bootstrap";
// import HeadingText from '../../HeadingText/HeadingText';
// import { ENVIRONMENT, GAME_TOKEN, S3_BUCKET } from '../../../../utils/Constants';
// import { CrossIcon } from '../../../../assets/svg/svgicons';
// import { apiCallPost } from '../../../../axiosApi/Axios';

// const SelectMode = ({ show, handleClose,itemToVerify }) => {
//     const [active, setActive] = useState("");

//     const gamePlay = async () => {
// 		// setIsLoading(true);
// 		try {
// 			let response = await apiCallPost("/api/v1/satyug/users/generate-token", {
// 				type: GAME_TOKEN.GameAccessToken,
// 			});
// 			if (response.status === 200) {
// 				window.location.replace(
// 					`${ENVIRONMENT.GAME_URL_RAM_MANDIR_NIRMAN}/?token=${response?.data?.token}&collectiveId=${itemToVerify?.id}`
// 				);
// 			}
// 		} catch (e) {
// 			// setIsValidated(false);
// 		}
// 	};

   

//     return (
//         <>
//             <Modal
//                 show={show}
//                 onHide={handleClose}
//                 className={styles.select_mode}
//                 centered
//                 backdropClassName='select_mode_backdrop'
//             >
//                 <button onClick={handleClose} className={styles.close_btn}>
//                     <CrossIcon />
//                 </button>
//                 <HeadingText
//                     heading="Ram Mandir ka Nirman "
//                     className={styles.heading}
//                 />
//                 <div className={styles.list}>
//                     <button
//                         className={`${styles.mode} ${active === "easy" ? styles.active : ""}`}
//                         onClick={() => setActive("easy")}
//                     >
//                         <h3>
//                             Easy Mode
//                         </h3>
//                         <img src={S3_BUCKET.EASY_MODE} alt="easy-mode" />
//                     </button>
//                     <button
//                         className={`${styles.mode} ${active === "hard" ? styles.active : ""}`}
//                         onClick={() => setActive("hard")}
//                     >
//                         <h3>
//                             Hard Mode
//                         </h3>
//                         <img src={S3_BUCKET.HARD_MODE} alt="easy-mode" />
//                     </button>
//                 </div>
//             </Modal>
//         </>
//     )
// }

// export default SelectMode



import React, { useState } from 'react';
import styles from "./SelectMode.module.scss";
import { Modal } from "react-bootstrap";
import HeadingText from '../../HeadingText/HeadingText';
import { ENVIRONMENT, GAME_TOKEN, S3_BUCKET } from '../../../../utils/Constants';
import { CrossIcon } from '../../../../assets/svg/svgicons';
import { apiCallPost } from '../../../../axiosApi/Axios';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../../../redux/loader';

const SelectMode = ({ show, handleClose, itemToVerify }) => {
    const [active, setActive] = useState("");
     const dispatch = useDispatch()
    const gamePlay = async (mode) => {
        dispatch(setLoader(true))
        try {
            let response = await apiCallPost("/api/v1/satyug/users/generate-token", {
                type: GAME_TOKEN.GameAccessToken,
            });

            if (response.status === 200) {
                let url;
                if (mode === "easy") {
                    url = `${ENVIRONMENT.GAME_URL_RAM_MANDIR_NIRMAN_EASY}/?token=${response?.data?.token}&collectiveId=${itemToVerify?.id}`;
                } else if (mode === "hard") {
                    url = `${ENVIRONMENT.GAME_URL_RAM_MANDIR_NIRMAN_HARD}/?token=${response?.data?.token}&collectiveId=${itemToVerify?.id}`;
                }

                window.location.replace(url);
            }
        } catch (e) {
        dispatch(setLoader(false))
            
        }
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className={styles.select_mode}
                centered
                backdropClassName='select_mode_backdrop'
            >
                <button onClick={handleClose} className={styles.close_btn}>
                    <CrossIcon />
                </button>
                <HeadingText
                    heading="Ram Mandir ka Nirman "
                    className={styles.heading}
                />
                <div className={styles.list}>
                    <button
                        className={`${styles.mode} ${active === "easy" ? styles.active : ""}`}
                        onClick={() => {
                            setActive("easy");
                            gamePlay("easy");
                        }}
                    >
                        <h3>
                            Easy Mode
                        </h3>
                        <img src={S3_BUCKET.EASY_MODE} alt="easy-mode" />
                    </button>
                    <button
                        className={`${styles.mode} ${active === "hard" ? styles.active : ""}`}
                        onClick={() => {
                            setActive("hard");
                            gamePlay("hard");
                        }}
                    >
                        <h3>
                            Hard Mode
                        </h3>
                        <img src={S3_BUCKET.HARD_MODE} alt="hard-mode" />
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default SelectMode;
