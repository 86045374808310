import React, { useRef } from 'react';
import Button from '../../Buttons/Button/Button';
import styles from "./GamePlayBefore.module.scss";
import { useNavigate } from 'react-router-dom';
import { S3_BUCKET } from '../../../../utils/Constants';

const GamePlayBefore = ({ handleClose, video, onEnded, showButton, backButton, itemtoVerify }) => {
    const navigate = useNavigate();
    const videoRef = useRef()
    const onVideoEnd = () => {
        //   setShowButton(true);
        videoRef.current.play()

    };

    return (
        <>
            <div
                className={styles.gamplay_modal}
            >
                <video ref={videoRef} playsInline autoPlay onEnded={onVideoEnd}>
                    <source src={S3_BUCKET.CINEMATIC_GAME} type="video/mp4" />
                </video>
                {/* {showButton && <button onClick={() => navigate("/participation")} className={`${styles.back_btn} ${styles.skip_btn}`} >Skip</button>} */}
                {backButton && <Button onClick={() => handleClose()} className={styles.back_btn}>Back</Button>}
            </div>
        </>
    )
}

export default GamePlayBefore