
import React, { useState, useEffect, useRef } from 'react'; // Import useEffect
import styles from "./SelectAssetGame.module.scss";
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { apiCallGet, apiCallPost } from '../../../axiosApi/Axios';
import { S3_BUCKET, S3_BUCKET_AUDIO } from '../../../utils/Constants';
import SuccessModal from '../../common/Modals/SuccessModal/SuccessModal';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../../hooks/useQuery';
import CertificateModal from '../../common/Modals/CertificateModal/CertificateModal';
import { setUserId } from '../../../redux/userDetails/userSlice';
import { setLoader } from '../../../redux/loader';
import SoundButton from '../../common/SoundButton/SoundButton';
import Cookies from 'js-cookie';
import Slider from 'react-slick';
import Loader from '../../common/Loader/Loader';


const SelectAssetGame = () => {
  const location = useLocation();
  const { success, before } = location?.state || {};
  const query = useQuery();
  const userId = query.get("userId")

  const email = useSelector((state) => state.userDetails.email);
  const [showCertificate, setShowCertificate] = useState(success || before || !userId ? false :true);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [isMuted, setIsMuted] = useState(true)
  const [data, setData] = useState(false);
  const [played, setPlayed] = useState(true);
  const { count, handleClick } = useOutletContext();
  const audioRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const hasMounted = useRef(false);



  useEffect(() => {
    if (success) {
      setShowModal(true);
    }
  }, [success]);

  useEffect(() => {
    let timeoutId;

    if (success) {
      timeoutId = setTimeout(() => {
        navigate('/after-video');
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [success]);


  const handleCloseModal = () => {
    window.history.replaceState({}, document.success)
    setShowModal(false);
  };

 
 
  const emailType = Cookies.get("googtrans")  === "undefined" ? "/en/en" : Cookies.get("googtrans")

  useEffect(() => {
    const payload = {
      userId: userId,
      lang : emailType.split('/').pop(),
    };
    const getCertificate = async () => {
      dispatch(setLoader(true))
      try {
        let response = await apiCallPost(
          `api/v1/satyug/users/generate/user/certificate`,
          payload,
          {},
          false,
          false
        );
        dispatch(setLoader(false))
        if (response.status === 200) {
          setData(response?.data) 
        }
      } catch (error) {
      dispatch(setLoader(false))
        console.log(error);
      }
    };
  if(userId){
    if (!hasMounted.current) {
      hasMounted.current = true;
      getCertificate() 
       }
  }  
    
  }, [userId]); // Add dependencies to the dependency array if needed
    
  const getProductDetails = async (assetName) => {
    setLoading(true)
    try {
      let response = await apiCallGet(
        `/api/v1/satyug/product/${assetName}/10/1`,
        {},
        true,
        true
      );
      setLoading(false)
     
      if (response.status === 200) {
        let payload = {
          gameUserId: query.get("userId"),
        };
        dispatch(setUserId(payload));
        navigate('/view-asset-activity', {
          state: {
            productDetails: response,
            isProduct: true
          }
          // replace: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    let intervalId;
    if (played) {
      intervalId = setInterval(playAudioInterval, 6000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [played]);

  function playAudio() {
    if (!played) {
      if (audioRef?.current) {
        audioRef.current.play();
      setPlayed(true);
      }
    }
  }



  function playAudioInterval() {
    playAudio();
  }


  function playAudioInterval() {
    if (audioRef?.current) {
      audioRef.current.play();
    }
  }

  
  const handleMuteToggle = (isMuted) => {
    setIsMuted(isMuted)
  };


  return (
    <>
    {loading ? <Loader/> :
    <section className={styles.select_asset}>
        <SoundButton onMuteToggle={handleMuteToggle} />
      <audio muted={isMuted} ref={audioRef} >
				<source src={S3_BUCKET_AUDIO.BUYAUDIO} type="audio/mpeg" />
 			</audio>
       <Container>
        <h2>Select type of asset you want to receive </h2>
        <Slider infinite={false} slidesToShow={5} responsive={[{
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
          }
        }]}>
          <div onClick={() => getProductDetails('t-shirt')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                {/* <p>₹ 350</p> */}
                <img src={S3_BUCKET.SHIRT} alt="shirt" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Jai Shri Ram T-SHIRT</h3>
          </div>

          <div onClick={() => getProductDetails('momento')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                <img src={S3_BUCKET.MOMENTO_BOX} alt="momento" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Digital Ram Mandir Momento Box</h3>
          </div>

          <div onClick={() => getProductDetails('Cap')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                {/* <p>₹ 175</p> */}
                <img src={S3_BUCKET.CAP} alt="cap" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Jai Shri Ram Cap</h3>
          </div>

          <div onClick={() => getProductDetails('photoFrame')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                {/* <p>₹ 175</p> */}
                <img src={S3_BUCKET.MOMENTO_WALL_FRAME} alt="photoFrame" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Digital Ram Mandir Momento Wall Frame</h3>
          </div>
          
          <div onClick={() => getProductDetails('brick')} className={`${styles.asset_box}`}>
            <div className={styles.asset_box_inner}>
              <div className={styles.asset_img}>
                {/* <p>₹ 175</p> */}
                <img src={S3_BUCKET.BRICK} alt="cap" />
              </div>
              <span className={styles.left_arrow}><img src={`${S3_BUCKET.IMAGES}/game-left-arrow.png`} alt="left-arrow" /></span>
              <span className={styles.right_arrow}><img src={`${S3_BUCKET.IMAGES}/game-right-arrow.png`} alt="right-arrow" /></span>
            </div>
            <h3>Jai Shri Ram Brick</h3>
          </div>
        </Slider>
      </Container>
      <SuccessModal
        show={showModal}
        handleClose={() => {
          handleCloseModal();
          navigate('/after-video')
        }}
        email={email}
      />
      <CertificateModal
        show={showCertificate}
        handleClose={() => setShowCertificate(false)}
        certificate={data}
        playAudio={playAudio}
        setPlayed={setPlayed}
        setIsMuted={setIsMuted}
      />
    </section>
     }
    </>
  )
}

export default SelectAssetGame;