import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "./Payment.module.scss";
import { Modal } from "react-bootstrap";
import { CoinIcons, CrossIcon } from "../../../assets/svg/svgicons";
import { Form, Formik } from "formik";
import Input from "../../common/Formik/Input/Input";
import Button from "../../common/Buttons/Button/Button";
import * as Yup from "yup";
import {
  ENVIRONMENT,
  FORMIK_REGEX,
  S3_BUCKET_AUDIO,
  getPriceForType,
  getThaliType,
} from "../../../utils/Constants";
import useRazorpay from "react-razorpay";
import { apiCallPatch, apiCallPost } from "../../../axiosApi/Axios";
import { toasts } from "../../../components/common/Toast/Toast";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../redux/loader";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../../hooks/useQuery";
import { setUserDetails } from "../../../redux/userDetails/userSlice";
import Cookies from "js-cookie";
const Payment = ({ show, handleClose }) => {

  const query = useQuery();
  const hasMounted = useRef(false);
  const dispatch = useDispatch();
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate()
  const userId = query.get("userId");
  const [orderId ,setOrderId]=useState()
  const [okId ,setokId]=useState("null")
  const [update ,setUpdate]=useState()
  const handleGoBackClick = () => {
    window.close();
  };


  useEffect(() => {
    if (userId) {
      if (!hasMounted.current) {
        // This block will only run on the initial render
        hasMounted.current = true;
      handleSubmit();
      }
    } else {
      navigate("/participation");
    }
  }, [userId]);
  
  const type = query.get('type')
  const emailType = Cookies.get("googtrans")  === "undefined" ? "/en/en" : Cookies.get("googtrans")

  const paymentHandler = async (res, orderId, status) => {
    try {
      let apiEndpoint;
      let requestData;
      if (query.get('type') === '1') {
        apiEndpoint = `/api/v1/satyug/donation/${orderId}`;
        requestData = {
        status: status,
        razorpayPaymentId: res.razorpay_payment_id || okId,
        lang : emailType.split('/').pop(),
        
        };
      } else {
        apiEndpoint = `/api/v1/satyug/orders/thali/${orderId}`;
        requestData = {
         status: status,
         razorpayPaymentId: res.razorpay_payment_id || okId,
         lang : emailType.split('/').pop(),
        }
      }
  
      const response = await apiCallPatch(apiEndpoint, requestData);

      if (response.status === 200) {
        toasts.success(response.message);
        dispatch(setLoader(false));
        handleGoBackClick()
      } else {
        dispatch(setLoader(false));
        toasts.error(response.message);
      }
    } catch (err) {
      dispatch(setLoader(false));
    }
  };
    
  const paymentSignature = async (res, orderId) => {
    dispatch(setLoader(true));
    try {
      const response = await axios.post(
        `${ENVIRONMENT.RAZORPAY_URL}/razorPay/v1/payment/verify_payment_signature`,
        {
          ...res,
          type: type === '1' ? 'Donation' : 'Thali', // Adjust 'OtherType' as needed
        }
      );
      if (response.status === 200) {
        paymentHandler(res, orderId, "SUCCESS");
      } else if (response.status === 400) {
        paymentHandler(res, orderId, "FAILED");
      } 
       

    } catch (err) {
      toasts.error(err);
      dispatch(setLoader(false));
    }
  };

  useEffect(()=> {
   if(update){
    paymentHandler("",orderId ,"CANCELLED")
   }
  },[update])


//   useEffect(() => {
//     const handleUnload = (event) => {
//         event.preventDefault();
//         paymentHandler("", orderId, "CANCELLED").then(() => {
//         });
//     };
//     window.onbeforeunload = handleUnload;
//     return () => {
//         window.onbeforeunload = null;
//     };
// }, [orderId, paymentHandler]);


useEffect(() => {
  const handleTabClose = event => {
    // Perform your API call or other actions here
    paymentHandler("", orderId, "CANCELLED");

    // You can provide a message to the user, but it won't prevent closing
    const confirmationMessage = 'Are you sure you want to exit?';
    event.returnValue = confirmationMessage;

    return confirmationMessage;
  };

  window.addEventListener('beforeunload', handleTabClose);

  return () => {
    // Cleanup: Remove the event listener when the component unmounts
    window.removeEventListener('beforeunload', handleTabClose);
  };
}, [orderId, paymentHandler]);




  const handlePayment = useCallback(
    async (orderId) => {
      const options = {
        key: type === '1' ? ENVIRONMENT.RAZORPAY_KEY_DONATION : ENVIRONMENT.RAZORPAY_KEY,
        order_id: orderId,
        handler: (res) => {
          paymentSignature( res, orderId);
        },
        theme: {
          color: "#3399cc",
        },    
        modal: {
          ondismiss: function () {
            setUpdate(true)
            // Call paymentHandler with the values directly
            // paymentHandler(getRes, orderIds, "CANCELLED");
          },
        },
        
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Razorpay]
  );
  const initialValues = {
    amount: "",
    emailId: "",
  };
  const handleSubmit = async () => {
    dispatch(setLoader(true))
  try {
    let apiEndpoint;
    let requestData;

    if (query.get('type') === '1') {
      apiEndpoint = "/api/v1/satyug/donation";
      requestData = {
        amount: query.get("amount"),
        userId: query.get("userId"),
      };
    } else {
      const typeFromQuery = parseInt(query.get("thaliType")); 
      const price = getPriceForType(typeFromQuery); 
      const des = getThaliType(typeFromQuery)
      apiEndpoint = "/api/v1/satyug/orders/thali";
      requestData = {
        price: price,
        userId: query.get("userId"),
        description : des
      }
    }

    const response = await apiCallPost(apiEndpoint, requestData);
    dispatch(setLoader(false))

    if (response.status === 200) {
      setOrderId(response.data.orderId)
      handlePayment(response.data.orderId);
      const setUserIdCookie = () => {
        const options = {
          domain: '.digitalrammandir.com',
          path: '/',
        };
      
        Cookies.set('orderId',response.data.orderId , options);
      };
      setUserIdCookie()
    } else {
      toasts.error("Something went wrong");
    }
  } catch (err) {
    // Handle errors if necessary
  }
};

  return (
    <></>
  );
};

export default Payment;
