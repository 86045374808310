import React from 'react';
import { S3_BUCKET, getBrowserType } from '../../../../utils/Constants';
import { useNavigate } from 'react-router-dom'
import styles from "../VideoSkip.module.scss";

const AfterVideo = () => {
  const navigate = useNavigate()
  const browserType = getBrowserType()
  const onVideoEnd = () => {
    navigate("/participation")
  }
  return (
    <>
      <section className={styles.video}>
        <video playsInline autoPlay onEnded={onVideoEnd}>
          {browserType === "Safari" ?
            <source src={S3_BUCKET.AFTER_END_BUY_SAFARI} type="video/mp4" />
            :
            <source src={S3_BUCKET.AFTER_END_BUY} type="video/mp4" />
          }
        </video>
      </section>
    </>
  )
}

export default AfterVideo