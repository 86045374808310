import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../Buttons/Button/Button";
import styles from "./SelectedCollectible.module.scss";
import { apiCallGet } from "../../../../axiosApi/Axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonModal from "../CommonModal/CommonModal";

const SelectedCollectible = ({ show, handleClose, play, userDetail }) => {
  const audioRef = useRef(null);
  const [data, setData] = useState();
  const [allow, setAllow] = useState(true);
  const [items, setItems] = useState()
  const userDetails = useSelector((state) => state.userDetails);

  const hasMounted = useRef(false);

  // const navigate = useNavigate()


  const getCollectiblesData = async () => {
    try {
      let response = await apiCallGet(
        `/api/v1/satyug/collectable/${userDetails?.collectiveId}/audio`,
        {},
        true,
        false
      );

      if (response.status === 200) {
        setData(response?.data);

      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userDetails?.newUserId) {
      setAllow(false);
      if (userDetails.newUserId) {
        getCollectiblesData()
      }
    }
  }, [userDetails.newUserId]);

  const playAudio = () => {
    // Check if the audio element exists and play it
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        // Attempt to play audio with user interaction
        console.error("Error playing audio:", error);
      });
    }
  };

  const handleContinue = () => {
    if (userDetails?.newUserId) {
      userDetail()
    }
    handleClose();
    play();
  };

  const handleAllow = () => {
    setAllow(false);
    getCollectiblesData()
    playAudio();
  };

  // const textType = (type) => {
  //   switch (type) {
  //     case "1":
  //       return 'प्रभु के शिंगार';
  //     case "2":
  //       return 'बागीचे का निर्माण,';
  //     case "3":
  //       return 'भोजनालय की दक्षिणा';
  //     default:
  //       return 'unknown';
  //   }
  // };

  const textTypeEng = (type) => {
    switch (type) {
      case "1":
        return 'Prabhu Ka Shingaar';
      case "2":
        return 'Bageeche Ka Nirmaan,';
      case "3":
        return 'Bhojnalaya Ki Dakshina';
      default:
        return 'unknown';
    }
  };

  // const text = textType(userDetails?.newType)
  const textEng = textTypeEng(userDetails?.newType)

  return (
    <>
      <CommonModal
        keyboard={true}
        show={show}
        centered
        onHide={handleClose}
        backdropClassName={"thanks_backdrop"}
        className={`${styles.thanks_modal} ${allow ? styles["small_modal"] : ""}`}
        backdrop="static"
      >
        {data?.[0]?.audio === null ? "" : (
          <audio ref={audioRef} src={data?.[0]?.audio} onLoad={playAudio} />
        )}
        {allow === true ? (
          <>
            <div className="w-100 text-center">
              <h3>{"Thank you for your important contribution in the construction of Ram temple"}</h3>
              <Button
                autoFocus
                text="Continue"
                onClick={handleAllow}
                className={styles.allow_btn}
                variant={"secondary-btn"}
              />
            </div>
          </>
        ) : (
          <>
            {" "}
            <ul>
              {
                data?.map(item => (
                  <li key={item}>
                    <img
                      className={styles.collectible_img}
                      src={item?.image || data?.image}
                      alt=""
                    />
                    {userDetails?.newType === "1" ?
                      <h3>Jai Shree Ram</h3> :
                      <h3>{item?.title || data?.title}</h3>
                    }
                  </li>
                ))
              }
            </ul>
            {/* Add a ref to the audio element */}
            {userDetails?.newUserId ? (
              <>
                {/* <p>
                  {`${text} में अपना महत्वपूर्ण योगदान देने के लिए धन्यवाद !`}
                </p> */}
                <p>
                  {`${textEng} Thank you for your contribution.`}
                </p>
              </>

            ) : (
              <>
                <p>
                Don't forget to receive your collectibles on email and WhatsApp by filling this form.
                </p>
              </>
            )}
            <Button
              autoFocus
              text="Continue"
              onClick={handleContinue}
              className={styles.continue_btn}
              variant={"secondary-btn"}
            />
          </>
        )}
      </CommonModal>
    </>
  );
};

export default SelectedCollectible;
