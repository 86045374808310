import React, { useEffect, useState, useRef } from "react";
import styles from "./Outside.module.scss";
import { Container } from "react-bootstrap";
import { Form, Formik } from "formik";
import Button from "../../common/Buttons/Button/Button";
import { useNavigate } from "react-router-dom";
import { S3_BUCKET, S3_BUCKET_AUDIO } from "../../../utils/Constants";


const Outside = () => {
//   const navigate = useNavigate();

const handleContinueClick = () => {
  window.open('/payment?userId=32d75a80-3154-42c2-9c66-4650731b771&type=1&amount=100', '_blank', 'width=900,height=900,align=center ');
};
  return (
    <>
        <>
          <section
            className={`${styles.ques_page} ${styles.show}`}
          >
            <Container>
              <div className={styles.ques_page_inner}>
                <img
                  data-aos="zoom-in"
                  src={`${S3_BUCKET.IMAGES}/archery.png`}
                  className={styles.archery_icon}
                  alt="archery-icon"
                />
                <h1>
                  Payment krna ka liya continue pr click kare
                </h1>
                <Formik
                  initialValues={{
                  }}
                //   onSubmit={onSubmit}
                >
                  {(formik) => {
                    return (
                      <Form>
                        <Button
                          className={styles.submit_action}
                          type="submit"
                          onClick={handleContinueClick}
                        //   disabled={!formik.dirty}
                          text="Continue"
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </Container>
          </section>
        </>
      {/* )} */}
    </>
  );
};

export default Outside;
