import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import animation from "../../../assets/animations/fuljhadia.json";
import { apiCallPost } from "../../../axiosApi/Axios";
import { useQuery } from "../../../hooks/useQuery";
import {
  setExsitingUserId,
  setUserDetails,
  setUserId,
  setUserReferKey
} from "../../../redux/userDetails/userSlice";
import {
  ENVIRONMENT,
  FORMIK_REGEX,
  GAME_TOKEN,
  S3_BUCKET,
  S3_BUCKET_AUDIO
} from "../../../utils/Constants";
import SecondaryBtn from "../../common/Buttons/SecondaryBtn/SecondaryBtn";
import FormControl from "../../common/Formik/FormControl";
import PhoneInput from "../../common/Formik/PhoneInput/PhoneInput";
import HeadingText from "../../common/HeadingText/HeadingText";
import LottieAnimation from "../../common/LottieAnimation/LottieAnimation";
import SelectedCollectible from "../../common/Modals/SelectedCollectible/SelectedCollectible";
import ContentLayout from "../../layouts/ContentLayout/ContentLayout";
import styles from "./VerifyEmailPage.module.scss";
import Backbutton from "../../common/Backbutton/Backbutton";

const VerifyEmailPage = () => {
  const initialValues = {
    name: "",
    whatsapp: "",
    emailId: "",
  };
  const dispatch = useDispatch();
  const [played, setPlayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const videoRef = useRef();
  const location = useLocation();
  const { itemToVerify } = location?.state || {};
  const userDetails = useSelector((state) => state.userDetails);

  const navigate = useNavigate();

  useEffect(() => {
    let intervalId;
    if (played) {
      intervalId = setInterval(playAudioInterval, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [played]);

  function playAudio() {
    if (!played) {
      if (videoRef?.current) {
        videoRef.current.play();
        setPlayed(true);
      }
    }
  }


  function playAudioInterval() {
    playAudio();
  }


  function playAudioInterval() {
    if (videoRef?.current) {
      videoRef.current.play();
    }
  }
  const gamePlay = async (id) => {
    setIsLoading(true);
    try {
      let response = await apiCallPost("/api/v1/satyug/users/generate-token", {
        type: GAME_TOKEN.GameAccessToken,
      });
      if (response.status === 200) {
        let gameUrl;
        switch (itemToVerify?.name?.toLowerCase()) {
         
          case 'mandir darshan':
            gameUrl = ENVIRONMENT.GAME_URL_MANDIR_DARSHAN;
            break;
          case 'prabhu shringar':
            gameUrl = ENVIRONMENT.GAME_URL_PRABHU_SHINGAR;
            break;
          case 'bhojnalaya ki dakshna':
            gameUrl = ENVIRONMENT.GAME_URL_BHOJNALYA;
            break;
            case 'bagiche ka nirman':
            gameUrl = ENVIRONMENT.GAME_URL_BAGICHA;
            break;
          default:
            console.error(`No URL defined for condition: ${itemToVerify}`);
            // Handle this error case as needed
            return;
        }

        window.location.replace(`${gameUrl}/?token=${response?.data?.token}&userId=${id}&collectiveId=${itemToVerify?.id}`);
      }
    } catch (e) {
    setIsLoading(false);

    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true)
      try {
        const response = await apiCallPost("/api/v1/satyug/users/playerExist", {
          email: values.emailId,
        });
    
        if (response.status === 200) {
           await gamePlay(response?.data?.userId);
           dispatch(setUserDetails(response?.data?.userId));
        } else {
          playAudio()
         setIsLoading(false)
         }
      } catch (err) {
        setIsLoading(false)
        console.error("Error while checking player existence:", err);
      }
  };



  const validationSchema = Yup.object().shape({
    emailId: Yup.string()
      .required("Email is required")
      .matches(FORMIK_REGEX.EMAIL_REGEX, "Enter valid email address"),
  });
  


  return (
    <>
 
      {/* <div onClick={playAudio}> */}
      <ContentLayout videoRef={videoRef} className={styles.verify_page}>
      <Backbutton className={styles.bckbtn} />
        <img
          data-aos="fade-up"
          className={styles.archery_icon}
          src={`${S3_BUCKET.IMAGESLOGO}`}
          alt="archery"
        />
        {/* <HeadingText heading="Satyug" className={styles.logo} /> */}
        <LottieAnimation className={styles.animation} animation={animation} />
        {/* <audio ref={audioRef} loop>
          <source src={S3_BUCKET_AUDIO.AFTER_GAME} type="audio/mpeg" />
        </audio> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <FormControl
                  className={styles.input}
                  type="email"
                  placeholder="Example@email.com"
                  label="Email ID :"
                  name="emailId"
                  formik={formik}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailId || userDetails?.userEmail}
                />
                <SecondaryBtn loading={isLoading} type="submit" text="Submit" />
              </Form>
            );
          }}
        </Formik>
      </ContentLayout>
      {/* </div> */}
    </>
  );
};

export default VerifyEmailPage;




