import React, { useEffect, useMemo, useState } from 'react';
import WelcomeToSiteModal from '../../common/Modals/WelcomeToSiteModal/WelcomeToSiteModal';
import GamePlayVideoReact from '../../common/Modals/GamePlayVideoReact/GamePlayVideoReact';
import { NETWORK_SPEED, S3_BUCKET, getBrowserType, safariLangType } from '../../../utils/Constants';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';  // Changed to useNavigate
import { useQuery } from '../../../hooks/useQuery';
import Maintenance from '../../common/Modals/Maintenance/Maintenance';

const WelCome = () => {
  const browserType = getBrowserType();
  const [welModal, setWelModal] = useState(true);
  const [show, setShow] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const speed = useSelector((state) => state.network.speed);
  const navigate = useNavigate();
  const query = useQuery();
  const [trackLang, setTrackLang] = useState('');
  useEffect(() => {
    const langValue = query.get('lang');
    if (langValue) {
      localStorage.setItem('userLanguage', langValue);
      const safariLang = safariLangType(langValue)
      setTrackLang(browserType === "Safari" ? safariLang : langValue)
      // Remove the 'lang' query parameter from the URL
      const { lang, ...newSearch } = query;
      navigate({
        search: new URLSearchParams(newSearch).toString(),
      });
    }
  }, [query, navigate]);
  useEffect(() => {
    setShow(browserType === 'Safari' ? true : false)
  }, [])
  let imageUrl = useMemo(() => {
    switch (speed) {
      case NETWORK_SPEED.LOW:
        return S3_BUCKET.BEFORE_GAME_360;
      case NETWORK_SPEED.MEDIUM:
        return S3_BUCKET.BEFORE_GAME_480;
      case NETWORK_SPEED.HIGH:
        return S3_BUCKET.BEFORE_GAME_720;
      default:
        return S3_BUCKET.BEFORE_GAME_360;
    }
  }, [speed]);

  const subtitles = [
    {
      kind: "subtitles",
      src: "https://dvf7opio6knc7.cloudfront.net/subtitle/en.vtt",
      srcLang: browserType === "Safari" ? "en" : "/en/en",
      default: false,
    },
    {
      kind: "subtitles",
      src: "https://dvf7opio6knc7.cloudfront.net/subtitle/hi.vtt",
      srcLang: browserType === "Safari" ? "hi" : "/en/hi",
      default: false,
    },
    {
      kind: "subtitles",
      src: "https://dvf7opio6knc7.cloudfront.net/subtitle/bn.vtt",
      srcLang: browserType === "Safari" ? "bn" : "/en/bn",
      default: false,
    },
    {
      kind: "subtitles",
      src: "https://dvf7opio6knc7.cloudfront.net/subtitle/gu.vtt",
      srcLang: browserType === "Safari" ? "gu" : "/en/gu",
      default: false,
    },
    {
      kind: "subtitles",
      src: "https://dvf7opio6knc7.cloudfront.net/subtitle/kn.vtt",
      srcLang: browserType === "Safari" ? "kn" : "/en/kn",
      default: false,
    },
    {
      kind: "subtitles",
      src: "https://dvf7opio6knc7.cloudfront.net/subtitle/mr.vtt",
      srcLang: browserType === "Safari" ? "mr" : "/en/mr",
      default: false,
    },
    {
      kind: "subtitles",
      src: "https://dvf7opio6knc7.cloudfront.net/subtitle/ta.vtt",
      srcLang: browserType === "Safari" ? "ta" : "/en/ta",
      default: false,
    },
    {
      kind: "subtitles",
      src: "https://dvf7opio6knc7.cloudfront.net/subtitle/te.vtt",
      srcLang: browserType === "Safari" ? "te" : "/en/te",
      default: false,
    },
  ];


  return (
    <>
      {show && (
        <GamePlayVideoReact
          subtitles={subtitles}
          trackLang={trackLang}
          videoUrl={imageUrl}
          onEnded={() => navigate('/participation')}
          handleClose={() => setShow(false)}
          isPlaying={isPlaying}
        />
      )}

      {/* <Maintenance show /> */}
      {welModal && (
        <WelcomeToSiteModal
          show={welModal}
          handleClose={() => setWelModal(false)}
          handleModal={() => setShow(true)}
          setIsPlaying={setIsPlaying}
        />
      )}
      {/* <Maintenance
       show={false}
      /> */}
    </>
  );
};

export default WelCome;
